import React, { FC, useMemo } from 'react';
import { Col, Divider, List, Row, Typography } from 'antd';
import dayjs from 'dayjs';

import {
  CurrencyType,
  CurrencyTypeNames,
  OrderStatusNames,
  TransportType,
  TransportTypeNames,
} from '../../reducers/orders/model';
import { gutterInner } from '../../forms/forklift';
import { ClientTypeNames } from '../../reducers/clients/model';
import { additionalServiceIcon } from './additionalServiceIcon';
import { formatPrice } from '../../utils/formatPrice';
import { getCountryData } from 'countries-list';
import { TaxRates } from '../../utils/taxRates';
import { PreviewListSC } from '../common/preview/PreviewListSC';
import { useGetOrder } from '../../services/apiClient/orders/orderQueries';
import { ProductsListSC } from '../common/productsList/ProductsListSC';
import { ProductGrossPriceSC } from '../common/productsList/ProductGrossPriceSC';
import { useGetOrderAdvancePayments } from '../../services/apiClient/advancePayments/advancePaymentsQueries';

const { Link, Text } = Typography;

type OrderProps = {
  orderId: string;
};

export const OrderPreview: FC<OrderProps> = props => {
  const { orderId } = props;

  const { data: order, isFetching } = useGetOrder(orderId);
  const { data: orderAdvancePayments } = useGetOrderAdvancePayments(orderId);

  const productsInOrder = order?.productsInOrder.map(product => {
    const moreProductData = order.products?.find(element => element._id === product.productId);

    return {
      ...product,
      ...moreProductData,
    };
  });

  const sumAdvancePayments = useMemo(() => {
    if (orderAdvancePayments) {
      return orderAdvancePayments.reduce((accumulator, currentValue) => accumulator + currentValue.price, 0);
    }
    return 0;
  }, [orderAdvancePayments]);

  return (
    <>
      <Row gutter={gutterInner}>
        <Col
          xs={24}
          md={12}
        >
          <PreviewListSC
            className='previewList firstList'
            size='small'
            loading={isFetching}
          >
            <List.Item className='quickViewListElement'>
              <List.Item.Meta
                title='Nazwa firmy:'
                description={order?.client?.companyName}
                className='listItemMeta'
              />
            </List.Item>
            <List.Item className='quickViewListElement'>
              <List.Item.Meta
                title='Typ klienta:'
                description={order && ClientTypeNames[order.clientType]}
                className='listItemMeta'
              />
            </List.Item>
            <List.Item className='quickViewListElement'>
              <List.Item.Meta
                title='Transport:'
                description={order && TransportTypeNames[order.transport]}
                className='listItemMeta'
              />
            </List.Item>
            <List.Item className='quickViewListElement'>
              <List.Item.Meta
                title='Adres do transportu:'
                description={order && order.transport === TransportType.SELLER ? order.transportAddress : 'nie dotyczy'}
                className='listItemMeta'
              />
            </List.Item>
            <List.Item className='quickViewListElement'>
              <List.Item.Meta
                title='Status:'
                description={order && OrderStatusNames[order.status]}
                className='listItemMeta'
              />
            </List.Item>

            <List.Item className='quickViewListElement'>
              <List.Item.Meta
                title='Waluta:'
                description={order && CurrencyTypeNames[order.currency]}
                className='listItemMeta'
              />
            </List.Item>
            <List.Item className='quickViewListElement'>
              <List.Item.Meta
                title='Płatne do:'
                description={dayjs(order?.paidAt).format('DD-MM-YYYY')}
                className='listItemMeta'
              />
            </List.Item>
            {orderAdvancePayments !== undefined && orderAdvancePayments.length > 0 && (
              <List.Item className='quickViewListElement'>
                <List.Item.Meta
                  title='Suma zaliczek:'
                  description={`${sumAdvancePayments} ${order?.currency ? CurrencyTypeNames[order.currency] : ``}`}
                  className='listItemMeta breakLine'
                />
              </List.Item>
            )}
          </PreviewListSC>
        </Col>
        <Col
          xs={24}
          md={12}
        >
          <PreviewListSC
            className='previewList'
            size='small'
            loading={isFetching}
          >
            <List.Item className='quickViewListElement'>
              <List.Item.Meta
                title='NIP:'
                description={order?.client?.companyNIP}
                className='listItemMeta'
              />
            </List.Item>
            <List.Item className='quickViewListElement'>
              <List.Item.Meta
                title='Adres:'
                description={`${order?.client?.companyStreet ?? ''}, ${order?.client?.companyPostCode ?? ''}, ${
                  order?.client?.companyCity ?? ''
                }, ${order?.client ? getCountryData(order.client.companyCountry).name : ''}`}
                className='listItemMeta'
              />
            </List.Item>
            <List.Item className='quickViewListElement'>
              <List.Item.Meta
                title='Osoba kontaktowa:'
                description={`${order?.client?.contactName ?? ''} ${order?.client?.contactSurname ?? ''}`}
                className='listItemMeta'
              />
            </List.Item>
            <List.Item className='quickViewListElement'>
              <List.Item.Meta
                title='E-mail:'
                description={
                  <Link href={`mailto:${order?.client?.contactEmail ?? ''}`}>{order?.client?.contactEmail}</Link>
                }
                className='listItemMeta'
              />
            </List.Item>
            <List.Item className='quickViewListElement'>
              <List.Item.Meta
                title='Telefon:'
                description={order?.client?.contactPhone}
                className='listItemMeta'
              />
            </List.Item>
            <List.Item className='quickViewListElement'>
              <List.Item.Meta
                title='Uwagi:'
                description={order?.notes}
                className='listItemMeta breakLine'
              />
            </List.Item>
          </PreviewListSC>
        </Col>
      </Row>
      <Divider />
      <Row className='orderPreview'>
        <Col span={24}>
          <ProductsListSC
            className='productsList'
            size='small'
            loading={isFetching}
          >
            {productsInOrder?.map(product => (
              <List.Item
                className='listItem'
                key={product.productId}
                extra={
                  <div className='quickViewListExtra'>
                    <div className='quickViewIconService'>
                      {product.technicalReview && additionalServiceIcon('technicalReview')}
                      {product.guarantee && additionalServiceIcon('guarantee')}
                      {product.udt && additionalServiceIcon('udt')}
                      {product.rectifier && additionalServiceIcon('rectifier')}
                    </div>
                  </div>
                }
              >
                <List.Item.Meta
                  className='listItemMeta'
                  key={product.productId}
                  title={`${product.internalId ?? ''} - ${product.brand?.name ?? ''} - ${product.type} - SN: ${
                    product.serialNumber ?? ''
                  }`}
                  description={
                    <div className='orderItem'>
                      <div className='orderQuantity'>
                        <strong>Cena:</strong>{' '}
                        <div>
                          {formatPrice(product.price, order?.currency ? order.currency : CurrencyType.PLN)}
                          {order?.client?.companyCountry === 'PL' && (
                            <ProductGrossPriceSC type='secondary'>
                              {formatPrice(product.price * TaxRates.TAX_23 + product.price, order.currency)}
                            </ProductGrossPriceSC>
                          )}
                        </div>
                      </div>
                      {product.notes && (
                        <div className='orderPrice breakLine'>
                          <strong>Uwagi:</strong> <div>{product.notes}</div>
                        </div>
                      )}
                    </div>
                  }
                />
              </List.Item>
            ))}
          </ProductsListSC>
        </Col>
      </Row>
      <Row justify='end'>
        <Col>
          <Text strong>
            Suma: {order?.price ? formatPrice(order.price, order.currency) : 0}
            {order?.client?.companyCountry === 'PL' && (
              <ProductGrossPriceSC type='secondary'>
                {formatPrice(order.price * TaxRates.TAX_23 + order.price, order.currency)}
              </ProductGrossPriceSC>
            )}
          </Text>
        </Col>
      </Row>
    </>
  );
};
